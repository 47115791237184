<template>
  <div>
    <v-app-bar dense fixed app color="blue darken-4" dark>
      <v-btn to="/menu_data" icon>
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <v-toolbar-title>โพยส่งออก</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>mdi-file-find</v-icon>
      </v-btn>
    </v-app-bar>
    <v-breadcrumbs :items="bc_items" class="pa-2" dense>
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <MenuTab @onTabChange="onTabChange" />

    <component v-bind:is="component" ref="com_tab" />
  </div>
</template>

<script>
import PageListTable from "@/components/Data/Export_Page_List/PageList/Datatable";
import PageBetTable from "@/components/Cus/Pages/PageBet/Datatable";
import PageWinTable from "@/components/Cus/Pages/PageWin/Datatable";
import CoverSheet from "@/components/Cus/Pages/CoverSheet/Main";

//   { text: "บัญชีรวม", id: 3 },
//   { text: "อัตราจ่าย", id: 4 },
//   { text: "บันทึก", id: 5 },
//   { text: "ใบปะหน้า", id: 6 },
//   { text: "ผู้ดูแล", id: 7 },

import globalFunctionMixin from "@/mixins/globalFunctionMixin";

import MenuTab from "@/components/Cus/Pages/Layout/Menu_Tab";

export default {
  name: "Line_Data",
  mixins: [globalFunctionMixin],
  components: {
    PageListTable,
    PageBetTable,
    PageWinTable,
    CoverSheet,
    MenuTab,
  },

  //get line name
  mounted() {
    this.ckLogin();

    this.bc_items[0].text = "โพยตีออก"
    this.bc_items[0].to = { path: "export_page" };

    this.bc_items[1].text = this.$route.query.bookie_id;

    // this.bc_items[2].to = {
    //   path: "pages_list",
    //   query: {
    //     line_id: this.$route.query.agent_id,
    //   },
    // };

    //     const resolved = this.$router.resolve({
    //   name: 'agent_data',
    //   params: { line_id: this.$route.query.line_id }
    // })
    // this.bc_items[1].to=resolved;
    // this.bc_items[1].to = "/agent_data?line_id=" + this.$route.query.line_id;
  },
  data: () => ({
    component: "PageListTable",
    BookieID: null,
    BookieName: null,
    bc_items: [
      {
        text: "a1 ถิง",
        disabled: false,
      },
      {
        text: "Link 2",
        disabled: false,
      },
    ],
  }),
  created() {
    //โหลด ตาราง
    this.BookieID = this.$route.query.bookie_id;
    this.fetch_table();
  },
  methods: {
    onTabChange(id) {
      switch (id) {
        case 0:
          this.component = PageListTable;
          break;
        case 1:
          this.component = PageBetTable;
          break;
        case 2:
          this.component = PageWinTable;
          break;
        case 3:
          this.component = CoverSheet;
          break;
      }
      //alert(id)
    },
    async fetch_table() {
      let credentials = {
        PeriodDT: sessionStorage.getItem("period"),
        BookieID: this.BookieID,
        ListType: "get_detail",
      };
      await this.$store.dispatch("bookie/get_detail", credentials).then(
        (response) => {
          //   console.log(this.$store.getters["agent/AgentDetail"][0].Nickname);
          this.bc_items[1].text =
            this.$route.query.bookie_id + " ( " + response[0].Name + " )";
        this.BookieName = response[0].Name;
          //   this.$store.getters["pages/Pages"];
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
};
</script>

<style></style>
