<template>
  <div
    style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;"
    unselectable="on"
    onselectstart="return false;"
    onmousedown="return false;"
    id="printSection"
  >
    <v-data-table
      :headers="headers"
      :items="pages_data"
      :items-per-page="10"
      item-key="PageNumber"
      single-select
      show-select
      class="elevation-1"
      hide-default-footer
      disable-pagination
      mobile-breakpoint="0"
      height="65vh"
      fixed-header
      dense
    >
      <!-- :class="{'h4': $vuetify.breakpoint. mdAndUp}" -->
      <template v-slot:top>
        <v-toolbar flat color="grey lighten-5">
          <v-btn rounded id="menu-activator" color="info" dark>
            <v-icon left>
              mdi-chevron-down
            </v-icon>
            จัดการ
          </v-btn>

          <v-menu activator="#menu-activator" offset-y>
            <v-list>
              <AddPage
                :BookieID="BookieID"
                @Reload="fetch_table"
                @EnterPage="EnterPage"
                v-if="!printing"
              />
              <v-list-item @click="MergePage" style="background-color: Beige;">
                <v-list-item-title style="color: black;">
                  <v-icon left>
                    mdi-set-merge
                  </v-icon>
                  รวมโพย</v-list-item-title
                >
              </v-list-item>
              <v-list-item
                @click="printSection"
                style="background-color: LightCyan;"
              >
                <v-list-item-title style="color: black;">
                  <v-icon left>
                    mdi-printer
                  </v-icon>
                  พิมพ์</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn
            class="ml-1"
            rounded
            outlined
            id="menu-activator"
            color="info"
            dark
          >
            <v-icon style="color: lightblue ;">
              mdi-bookmark
            </v-icon>
          </v-btn>
          <v-divider class="mx-4 info" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn rounded outlined color="cyan">
            <v-icon left>
              mdi-filter-variant
            </v-icon>
            ตัวกรอง
          </v-btn>
        </v-toolbar>
        <v-alert dense text class="text-center" type="info">
          แสดง <strong>ทั้งหมด</strong> รวม
          <strong>{{ pages_count }}</strong> รายการ
        </v-alert>
      </template>

      <template v-slot:item="{ item }">
        <tr
          @contextmenu="popup_menu($event, item)"
          @click="row_click(item)"
          :class="{ 'blue lighten-5': item.PageNumber === selectedId }"
        >
          <td>
            <v-checkbox
              v-model="item.checked"
              dense
            ></v-checkbox>
          </td>
          <td align="end" :class="id_column">{{ item.PageNumber }}.</td>
          <td align="end">
            {{ add_comma(item.PriceSum) }}
          </td>
          <td align="end">
            {{ add_comma(item.PriceSumLoy) }}
          </td>

          <td align="center">
            <v-icon :class="getPrintColor(item.Print)" left>
              {{ getPrintText(item.Print) }}
            </v-icon>
          </td>
          <td
            align="center"
            :class="getStatusColor(item.Status, item.isAccept)"
          >
            {{ getStatusText(item.Status, item.isAccept) }}
          </td>
          <td align="end">
            {{ add_comma(item.WinSum) }}
          </td>
          <td align="end">
            {{ add_comma(item.WinSumLoy) }}
          </td>
          <td align="start">
            {{ getDT(item.UpdateDT) }}
          </td>
        </tr>
      </template>

      <template slot="body.prepend">
        <tr>
          <td></td>
          <td align="end" :class="id_column_sum">รวม</td>
          <td :class="OddHeader()" align="end">
            {{ add_comma(pages_detail["PriceSum"]) }}
          </td>
          <td :class="EvenHeader()" align="end">
            {{ add_comma(pages_detail["PriceSumLoy"]) }}
          </td>
          <td :class="OddHeader()" align="end"></td>
          <td :class="EvenHeader()" align="center">
            รอรับ ({{ add_comma(pages_detail["WaitingSend"]) }})
          </td>
          <td :class="OddHeader()" align="end">
            {{ add_comma(pages_detail["WinSum"]) }}
          </td>
          <td :class="EvenHeader()" align="end">
            {{ add_comma(pages_detail["WinSumLoy"]) }}
          </td>

          <td :class="OddHeader()" align="center"></td>
        </tr>
      </template>
    </v-data-table>

    <PopupMenu ref="PopupMenu1" @del="OnDeletePage" />
  </div>
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";
import PopupMenu from "@/components/Data/Export_Page_List/PageList/Popup_Menu";
import AddPage from "@/components/Data/Export_Page_List/PageList/Add_Data";

export default {
  name: "PageList_Datatable",
  mixins: [globalFunctionMixin],

  components: {
    PopupMenu,
    AddPage,
  },
  created() {
    //โหลด ตาราง
    this.BookieID = this.$route.query.bookie_id;
    this.fetch_table();
  },
  computed: {
    //ดึงข้อมูลจาก vuex มาแสดงในตาราง
    pages_data() {
      return this.$store.getters["export_page/Pages"];
    },
    pages_count() {
      return this.$store.getters["export_page/Pages"].length;
    },
    pages_detail() {
      return this.$store.getters["export_page/PagesDetail"];
    },
  },
  methods: {
    async fetch_table() {
      let credentials = {
        PeriodDT: sessionStorage.getItem("period"),
        BookieID: this.BookieID,
        ListType: "page_list",
      };
      await this.$store.dispatch("export_page/get_page_list", credentials).then(
        (response) => {
          console.log(response);
        },
        (error) => {
          console.log(error);
        }
      );
    },
    async MergePage() {
      let PagesList = "";
      this.$store.getters["export_page/Pages"].forEach((element) => {
        //if status
        if(element["checked"]==true){
            if(PagesList!="") PagesList+=",";
            PagesList+=element["PageNumber"];
        }
      });
      let credentials = {
        PeriodDT: sessionStorage.getItem("period"),
        BookieID: this.BookieID,
        Pages: PagesList,
      };
      await this.$store.dispatch("export_page/merge_page", credentials).then(
        (response) => {
          this.$store.getters["export_page/Pages"].forEach((element) => {
            element["checked"] = false;
          });

          console.log(response);
        },
        (error) => {
          console.log(error);
        }
      );
    },
    async OnDeletePage(PageNumber) {
        let credentials = {
        PeriodDT: sessionStorage.getItem("period"),
        BookieID: this.BookieID,
        PageNumber: PageNumber,
      };
      await this.$store.dispatch("export_page/del_page", credentials).then(
        (response) => {
          console.log(response);
        },
        (error) => {
          console.log(error);
        }
      );
    },
    getPrintText(isPrint) {
      const printText = ["mdi-close", "mdi-check"];
      return printText[parseInt(isPrint)];
    },
    getPrintColor(isPrint) {
      const printColor = ["red--text", "green--text"];
      return printColor[parseInt(isPrint)];
    },
    getStatusText(Status, isAccept) {
      if (Status == "0") {
        return "กำลังตัด";
      } else if (Status == "1") {
        if (isAccept == "0") return "รอรับ";
        else return "รับแล้ว";
      } else {
        return "ตีกลับ";
      }
    },
    getStatusColor(Status, isAccept) {
      if (Status == "0") {
        return "grey--text";
      } else if (Status == "1") {
        if (isAccept == "0") return "amber--text text--darken-4";
        else return "green--text text--darken-4";
      } else {
        return "red--text";
      }
    },
    EnterPage(PageNumber) {
      this.$router.push({
        path: "export_page_edit",
        query: {
          bookie_id: this.BookieID,
          page_number: PageNumber,
        },
      });
    },
    OddHeader() {
      return "blue lighten-4 black--text";
    },
    EvenHeader() {
      return "blue lighten-4 black--text";
    },
    row_click: function(item) {
      this.selectedId = item.PageNumber;
      this.$set(item, "selected", true);
      //   console.log(item.id);
    },
    popup_menu: function(e, item) {
      e.preventDefault();
      this.$refs.PopupMenu1.Hide(e.clientX, e.clientY);
      this.$nextTick(() => {
        this.$refs.PopupMenu1.Show(this.BookieID, item.PageNumber);
        this.selectedId = item.PageNumber;
        //console.log(item);
      });
    },
    printSection() {
      this.printing = true;
      console.log(this.printing);
      const options = {
        name: "_blank",
        specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
        //   styles: [
        //     'css/print.css',
        //   ]
      };
      this.$nextTick(() => {
        this.$htmlToPaper("printSection", options, () => {
          this.printing = false;
        });
      });
    },
  },
  data: () => ({
    BookieID: "",
    selectedId: -1,

    printing: false,

    // // right click
    // showMenu: false,
    // x: 0,
    // y: 0,

    headers: [
      {
        text: "ใบที่",
        value: "PageNumber",
        width: "1%",
      },
      {
        text: "ยอดรวม",
        value: "BetSum",
        align: "end",
        width: "10%",
        class: "brown lighten-5",
      },
      {
        text: "ยอดลอย",
        value: "BetLoy",
        align: "end",
        width: "10%",
        class: "brown lighten-5",
        // sortable: false,
      },
      {
        text: "พิมพ์",
        value: "Status",
        align: "center",
        width: "15%",
        class: "brown lighten-5",
      },
      {
        text: "สถานะ",
        value: "Status",
        align: "center",
        width: "15%",
        class: "brown lighten-5",
      },
      {
        text: "ถูกรวม",
        value: "WinSum",
        align: "end",
        width: "10%",
        class: "brown lighten-5",
      },
      {
        text: "ถูกลอย",
        value: "WinLoy",
        align: "end",
        width: "10%",
        class: "brown lighten-5",
      },
      {
        text: "อัพเดตล่าสุด",
        value: "Status",
        align: "start",
        width: "29%",
        class: "brown lighten-5",
      },
    ],
    id_column: "id_column_class",
    id_column_sum: "id_column_sum_class",
  }),
};
</script>

<style scoped>
::v-deep .id_column_class {
  background-color: #0d47a1 !important;
  color: whitesmoke;
  /* text-align: center !important;  
  padding-top: 10px !important; */
  /* position:absolute;  */
  position: sticky !important;
  width: 4%;
  left: 0;
}
::v-deep .id_column_sum_class {
  background-color: #0d47a1 !important;
  color: whitesmoke;
  /* text-align: center !important;  
  padding-top: 10px !important; */
  /* position:absolute;  */
  position: sticky !important;
  width: 4%;
  left: 0;
}
/* ::v-deep table {
  table-layout : fixed;
} */

::v-deep table thead th:nth-child(2) {
  position: sticky !important;
  left: 0 !important;
  z-index: 3 !important;
  background: #0d47a1 !important;
  color: whitesmoke !important;
  padding-top: 15px !important;
}

::v-deep table thead th:last-child {
  padding-left: 15px !important;
}

::v-deep table thead th {
  padding: 3px;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 25vw;
  background: rgb(255, 255, 255);
  /* font-size: 1.0vw !important; */
  /* font-size: 1.4vmax !important; */
  font-size: 18px !important;
  /* font-size: 110% !important; */
}

::v-deep table {
  background-color: #eceff1;
}

::v-deep tr:nth-child(odd) {
  background-color: #edfafc;
}

::v-deep table tbody tr td {
  font-size: 1em !important;
  color: rgb(88, 88, 88);
  font-weight: normal;
  border-right: 1px solid;
  border-color: lightgray;
}

@media only screen and (min-width: 850px) {
  ::v-deep table tbody tr td {
    font-size: 1em !important;
    color: rgb(88, 88, 88);
    font-weight: bold;
  }
}

/* /deep/ tr.v-data-table__selected {
    background: lightgray !important;
    color: black;
    font-weight: bold;
  } */
</style>
